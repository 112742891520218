<template>
    <div class="w-full h-full px-2 md:shadow-none">
        <div id="terminal" class="w-full h-full"></div>
    </div>
</template>

<script>
import Term from '../../utils/term';

export default {
    name: 'DocsTerminal',

    props: {
        baseCommand: {
            type: String,
            required: true,
        },
        onExit: {
            type: Function,
            required: true,
        },
    },

    data() {
        return {
            terminal: null,
        };
    },

    mounted() {
        this.setupTerminal();
    },

    methods: {
        setupTerminal() {
            this.terminal = new Term();
            this.terminal.start(document.getElementById('terminal'), this.onExit, this.baseCommand);
        },
    },

    components: {

    },
};
</script>

<style lang="postcss">
div.terminal.xterm {
    height: 100% !important;
}
</style>
