import { APP_CONFIG } from '../..';

let socket;
let callback = () => {};

const isReady = () => socket && socket.readyState === 1;

export const initialize = (onData) => new Promise((resolve, reject) => {
    if (isReady()) {
        return;
    }
    socket = new WebSocket(`${APP_CONFIG.piston.baseUrl}/api/v2/connect`);
    callback = onData;
    socket.addEventListener('open', () => { resolve(); });
    socket.addEventListener('error', (evt) => { reject(evt.data); });
    socket.addEventListener('message', (evt) => {
        try {
            const resp = JSON.parse(evt.data);
            switch (resp.type) {
            case 'data': {
                callback(resp.data);
                break;
            }
            case 'exit': {
                callback('', true);
                break;
            }
            default:
                break;
            }
        } catch (ex) { /* no-op */ }
    });
});

export const execute = async (data) => {
    if (!isReady()) {
        await initialize(callback);
    }
    return socket.send(JSON.stringify(data));
};
