import { Terminal } from 'xterm';
import { FitAddon } from 'xterm-addon-fit';

import { initialize, execute } from './api/socket/piston';

const theme = {
    foreground: '#eff0eb',
    background: '#2d3748', // '#282a36',
    selection: '#97979b33',
    black: '#282a36',
    brightBlack: '#686868',
    red: '#ff5c57',
    brightRed: '#ff5c57',
    green: '#5af78e',
    brightGreen: '#5af78e',
    yellow: '#f3f99d',
    brightYellow: '#f3f99d',
    blue: '#57c7ff',
    brightBlue: '#57c7ff',
    magenta: '#ff6ac1',
    brightMagenta: '#ff6ac1',
    cyan: '#9aedfe',
    brightCyan: '#9aedfe',
    white: '#f1f1f0',
    brightWhite: '#eff0eb',
};

function Term() {
    this.term = new Terminal({
        theme,
        cursorStyle: 'bar',
        disableStdin: false,
        cursorBlink: true,
        allowProposedApi: true,
        allowTransparency: true,
        rightClickSelectsWord: true,
    });
    this.fitAddon = new FitAddon();
    this.term.loadAddon(this.fitAddon);

    this.commands = {
        curl: {
            f: () => {
                execute({
                    type: 'init',
                    language: 'bash',
                    version: '*',
                    files: [{ name: 'curl.sh', content: `${this.command} --silent` }],
                }).catch((err) => {
                    this.term.writeln(`\x1b[31;1m${err ? (err.message || err) : 'Ooops :\'( Please try again!'}\x1b[0m`);
                    this.prompt();
                });
            },
            description: 'Runs a curl command',
        },
        clear: {
            f: () => {
                this.term.reset();
                this.term.clear();
                this.reset();
                this.prompt();
            },
            description: 'Clear the terminal',
        },
        exit: {
            f: () => {
                this.term.reset();
                this.onExit();
            },
            description: 'Closes the terminal',
        },
        help: {
            f: () => {
                this.term.writeln([
                    'Try some of the commands below.',
                    '',
                    ...Object.keys(this.commands).map((e) => `  ${e.padEnd(10)} ${this.commands[e].description}`),
                ].join('\n\r'));
                this.prompt();
            },
            description: 'Prints this help message',
        },
    };

    this.command = '';
    this.prompt = (text = '') => {
        this.command = text;
        this.term.write(`\r\n$ ${text}`);
    };

    this.reset = () => {
        this.term.write('\x1b[?45h'); // reverse-wraparound mode
        this.term.writeln([''].join('\n\r'));
        this.term.writeln('Type `help` for more info.');
    };

    this.runCommand = (text) => {
        const command = text.trim().split(' ')[0];
        if (command.length > 0) {
            this.term.writeln('');
            if (command in this.commands) {
                this.commands[command].f();
                return;
            }
            this.term.writeln(`${command}: command not found`);
        }
        this.prompt();
    };

    this.term.onData((e) => {
        switch (e) {
        case '\u0003': { // Ctrl+C
            this.term.write('^C');
            this.prompt();
            break;
        }
        case '\r': { // Enter
            this.term.write('\n');
            this.runCommand(this.command);
            this.command = '';
            break;
        }
        case '\u007F': { // DEL
            if (this.command.length > 0) {
                this.term.write('\b \b');
                this.command = this.command.substr(0, this.command.length - 1);
            }
            break;
        }
        default:
            // eslint-disable-next-line
            if (e >= String.fromCharCode(0x20) && e <= String.fromCharCode(0x7E) || e >= '\u00a0') {
                this.command += e;
                this.term.write(e);
            }
        }
    });
    this.start = (container, onExit = () => {}, text = '') => {
        // eslint-disable-next-line
        if (this.term._initialized) {
            return;
        }

        this.term.open(container);
        this.fitAddon.fit();

        // eslint-disable-next-line
        this.term._initialized = true;
        this.onExit = onExit;
        this.reset();

        this.prompt(text);
        this.term.focus();

        document.querySelector('.xterm').addEventListener('wheel', (e) => {
            if (this.term.buffer.active.baseY > 0) {
                e.preventDefault();
            }
        });

        initialize((data, final = false) => {
            this.term.write(data);
            if (final) {
                this.prompt();
            }
        }).catch((err) => {
            this.term.writeln(`\r\n\x1b[31;1m${err ? (err.message || err) : 'Ooops :\'( Failed to initialize terminal. Please try again!'}\x1b[0m`);
            this.prompt();
        });
    };
}

export default Term;
